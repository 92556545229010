/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-webp !*/
!(function(e, n, A) {
  function o(e, n) {
    return typeof e === n;
  }
  function a() {
    var e, n, A, a, t, i, r;
    for (var l in s)
      if (s.hasOwnProperty(l)) {
        if (
          ((e = []),
          (n = s[l]),
          n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length))
        )
          for (A = 0; A < n.options.aliases.length; A++) e.push(n.options.aliases[A].toLowerCase());
        for (a = o(n.fn, 'function') ? n.fn() : n.fn, t = 0; t < e.length; t++)
          (i = e[t]),
            (r = i.split('.')),
            1 === r.length
              ? (Modernizr[r[0]] = a)
              : (!Modernizr[r[0]] ||
                  Modernizr[r[0]] instanceof Boolean ||
                  (Modernizr[r[0]] = new Boolean(Modernizr[r[0]])),
                (Modernizr[r[0]][r[1]] = a)),
            f.push((a ? '' : 'no-') + r.join('-'));
      }
  }
  function t(e) {
    var n = u.className,
      A = Modernizr._config.classPrefix || '';
    if ((c && (n = n.baseVal), Modernizr._config.enableJSClass)) {
      var o = new RegExp('(^|\\s)' + A + 'no-js(\\s|$)');
      n = n.replace(o, '$1' + A + 'js$2');
    }
    Modernizr._config.enableClasses &&
      ((n += ' ' + A + e.join(' ' + A)), c ? (u.className.baseVal = n) : (u.className = n));
  }
  function i(e, n) {
    if ('object' == typeof e) for (var A in e) l(e, A) && i(A, e[A]);
    else {
      e = e.toLowerCase();
      var o = e.split('.'),
        a = Modernizr[o[0]];
      if ((2 == o.length && (a = a[o[1]]), 'undefined' != typeof a)) return Modernizr;
      (n = 'function' == typeof n ? n() : n),
        1 == o.length
          ? (Modernizr[o[0]] = n)
          : (!Modernizr[o[0]] || Modernizr[o[0]] instanceof Boolean || (Modernizr[o[0]] = new Boolean(Modernizr[o[0]])),
            (Modernizr[o[0]][o[1]] = n)),
        t([(n && 0 != n ? '' : 'no-') + o.join('-')]),
        Modernizr._trigger(e, n);
    }
    return Modernizr;
  }
  var s = [],
    r = {
      _version: '3.6.0',
      _config: { classPrefix: '', enableClasses: !0, enableJSClass: !0, usePrefixes: !0 },
      _q: [],
      on: function(e, n) {
        var A = this;
        setTimeout(function() {
          n(A[e]);
        }, 0);
      },
      addTest: function(e, n, A) {
        s.push({ name: e, fn: n, options: A });
      },
      addAsyncTest: function(e) {
        s.push({ name: null, fn: e });
      },
    },
    Modernizr = function() {};
  (Modernizr.prototype = r), (Modernizr = new Modernizr());
  var l,
    f = [];
  !(function() {
    var e = {}.hasOwnProperty;
    l =
      o(e, 'undefined') || o(e.call, 'undefined')
        ? function(e, n) {
            return n in e && o(e.constructor.prototype[n], 'undefined');
          }
        : function(n, A) {
            return e.call(n, A);
          };
  })();
  var u = n.documentElement,
    c = 'svg' === u.nodeName.toLowerCase();
  (r._l = {}),
    (r.on = function(e, n) {
      this._l[e] || (this._l[e] = []),
        this._l[e].push(n),
        Modernizr.hasOwnProperty(e) &&
          setTimeout(function() {
            Modernizr._trigger(e, Modernizr[e]);
          }, 0);
    }),
    (r._trigger = function(e, n) {
      if (this._l[e]) {
        var A = this._l[e];
        setTimeout(function() {
          var e, o;
          for (e = 0; e < A.length; e++) (o = A[e])(n);
        }, 0),
          delete this._l[e];
      }
    }),
    Modernizr._q.push(function() {
      r.addTest = i;
    }),
    Modernizr.addAsyncTest(function() {
      function e(e, n, A) {
        function o(n) {
          var o = n && 'load' === n.type ? 1 == a.width : !1,
            t = 'webp' === e;
          i(e, t && o ? new Boolean(o) : o), A && A(n);
        }
        var a = new Image();
        (a.onerror = o), (a.onload = o), (a.src = n);
      }
      var n = [
          { uri: 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=', name: 'webp' },
          {
            uri:
              'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAABBxAR/Q9ERP8DAABWUDggGAAAADABAJ0BKgEAAQADADQlpAADcAD++/1QAA==',
            name: 'webp.alpha',
          },
          {
            uri:
              'data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
            name: 'webp.animation',
          },
          { uri: 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=', name: 'webp.lossless' },
        ],
        A = n.shift();
      e(A.name, A.uri, function(A) {
        if (A && 'load' === A.type) for (var o = 0; o < n.length; o++) e(n[o].name, n[o].uri);
      });
    }),
    a(),
    delete r.addTest,
    delete r.addAsyncTest;
  for (var p = 0; p < Modernizr._q.length; p++) Modernizr._q[p]();
  e.Modernizr = Modernizr;
})(window, document);
